@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes glisten {
  0% {
    filter: brightness(85%); /* slightly darker */
  }
  50% {
    filter: brightness(115%); /* slightly lighter */
    text-shadow: 0 0 5px currentColor;
  }
  100% {
    filter: brightness(85%); /* slightly darker */
  }
}
@keyframes glisten-heirloom {
  0% {
    filter: brightness(85%); /* slightly darker */
    text-shadow: 0 0 10px currentColor, 0 0 15px currentColor;
  }
  50% {
    filter: brightness(115%); /* slightly lighter */
    text-shadow: 0 0 5px currentColor;
  }
  100% {
    filter: brightness(85%); /* slightly darker */
    text-shadow: 0 0 10px currentColor, 0 0 15px currentColor;
  }
}

@keyframes marquee {
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
}

.text-assembled {
  color: #c59f3d;
  animation: glisten 2s infinite linear;
}

.text-limited {
  color: #f9a8d4;
  animation: glisten 2s infinite linear;
}

.text-heirloom {
  color: white;

  overflow: visible;
  animation: glisten-heirloom 2s infinite linear;
}